<template>
  <multiselect
    :clear-on-select="clearOnSelect"
    :close-on-select="closeOnSelect"
    :hide-selected="hideSelected"
    :id="id"
    :internal-search="internalSearch"
    :label="label"
    :limit="limit"
    :loading="loading"
    :max-height="maxHeight"
    :multiple="multiple"
    :open-direction="openDirection"
    :option-height="optionHeight"
    :options-limit="optionsLimit"
    :options="options"
    :placeholder="placeholder"
    :preserve-search="preserveSearch"
    :searchable="searchable"
    :show-labels="showLabels"
    :show-no-results="showNoResults"
    :taggable="taggable"
    v-bind:value="value"
    @input="$emit('input', $event)"
    @search-change="$emit('search-change', $event)"
    @select="$emit('select', $event)"
  >
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "SfMultiselect",
  components: {
    Multiselect,
  },
  props: {
    clearOnSelect: Boolean,
    closeOnSelect: Boolean,
    hideSelected: Boolean,
    id: String,
    internalSearch: Boolean,
    label: String,
    limit: Number,
    loading: Boolean,
    maxHeight: Number,
    multiple: Boolean,
    openDirection: String,
    optionHeight: Number,
    options: Array,
    optionsLimit: Number,
    placeholder: String,
    preserveSearch: Boolean,
    searchable: Boolean,
    showLabels: Boolean,
    showNoResults: Boolean,
    taggable: Boolean,
    value: [Object, Array],
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";

::v-deep .multiselect__option--selected.multiselect__option--highlight,
::v-deep .multiselect__option--highlight {
  background: none;
  color: $dark_grey;
  font-weight: 700;
}

::v-deep .multiselect__option--selected.multiselect__option--highlight,
::v-deep .multiselect__option--selected {
  background: $gray-100;
}

::v-deep .multiselect__option--selected {
  font-weight: 400;
}
</style>

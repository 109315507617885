<template>
  <div>
    <div class="compact-grid">
      <template v-for="value in values">
        <div :key="value.id" class="tag_list_multiple_item">
          {{ value[keyName] }}
          <tags-detail :tagLabels="value.tags"></tags-detail>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TagsDetail from "../interface/TagsDetail";

export default {
  name: "TaggedListMultiple",
  components: {
    TagsDetail,
  },
  props: ["values", "keyName"],
};
</script>

<style scoped>
.compact-grid {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 0;
  margin-right: 0.8rem;
}
</style>

<template>
  <div class="d-flex sf-file-upload" :class="isValid">
    <b-form-input
      type="text"
      :value="fileName"
      size="sm"
      :placeholder="placeholder"
      :class="isValid"
      disabled
      class="bg-white sf-file-upload-file-name"
    ></b-form-input>
    <label :for="id" class="ml-3">
      <input
        :id="id"
        ref="browseinput"
        class="d-none"
        type="file"
        @change="fileInputHandler"
      />
      <span
        tabindex="0"
        role="button"
        @keyup.enter="openFileBrowseDialog"
        class="btn btn-outline-primary btn-sm"
        >Browse</span
      >
    </label>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "SfFileUpload",
  props: {
    value: {type: [Object, File]},
    placeholder: {String},
    state: {
      type: [Boolean, null],
      required: false,
    },
  },
  data() {
    return {
      file: null,
      id: null,
    };
  },
  mounted() {
    this.id = "file_upload_" + _.uniqueId();
  },
  computed: {
    fileName() {
      return !this.file ? "" : this.file.name;
    },
    isValid() {
      if (this.state === null) {
        return "";
      } else if (this.state) {
        return "is-valid";
      } else {
        return "is-invalid";
      }
    },
  },
  methods: {
    openFileBrowseDialog() {
      const elem = this.$refs.browseinput;
      elem.click();
    },
    fileInputHandler(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.file = files[0];
      this.$emit("input", this.file);
    },
  },
};
</script>

<style scoped>
.sf-file-upload-file-name:disabled {
  color: black;
}
</style>

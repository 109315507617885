<template>
  <b-modal
    :id="modalId"
    size="lg"
    :title="title"
    @hidden="resetModal"
    @show="showModal"
  >
    <template v-slot:default>
      <b-form
        name="memberForm"
        role="form"
        class="m-2 validated"
        v-on:submit.prevent
        novalidate
      >
        <b-row>
          <b-col cols="9">
            <b-form-group id="fg-file-upload">
              <label class="label-bold" for="file-upload">{{
                uploadLabel
              }}</label>
              <sf-file-upload
                id="file-upload"
                v-model="file"
                :state="!$v.file.$dirty ? null : !$v.file.$invalid"
                :placeholder="currentFileName"
                @input="fileInputChangeHandler"
              >
              </sf-file-upload>
              <b-form-invalid-feedback id="file-invalid-feedback">
                <div v-if="$v.file.$invalid">
                  <div v-if="!$v.file.required">This is a required field.</div>
                  <div v-if="!$v.file.maxFileSize">
                    The selected file must be less than
                    {{ byteFormatter(maxFileSize) }}.
                  </div>
                  <div v-if="!$v.file.maxFileNameLength">
                    File name must be less than
                    {{ maxFileNameLength }} characters.
                  </div>
                  <div v-if="!$v.file.fileType">
                    The selected file type is restricted by SmartyFile.
                  </div>
                  <!-- Show server side file validation errors-->
                  <div v-for="error in serverSideErrors.file" :key="error">
                    {{ error }}
                  </div>
                </div>
              </b-form-invalid-feedback>
              <!-- This is placed here because we dont disable the Add/Save button for this type of error-->
              <div
                class="custom-invalid-feedback"
                v-if="serverSideErrors.virusScannerError"
              >
                {{ serverSideErrors.virusScannerError }}
              </div>
              <div>
                <span v-if="isInEditMode" class="small"
                  >Uploading a new file will replace the stored file. This will
                  not change files already attached to forms.</span
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <span class="label-bold">File size</span>
            <div class="mt-3">
              <span>{{ formattedFileSize }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-group class="mb-0">
              <label class="label-bold" for="description"
                >Description <small class="text-muted"> Optional</small></label
              >
              <b-form-textarea
                id="description"
                name="description"
                size="sm"
                v-model="description"
                :state="
                  !$v.description.$dirty ? null : !$v.description.$invalid
                "
                @input="$v.description.$touch()"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <b-form-invalid-feedback id="description-invalid-feedback"
                >This is an optional field. It must be at most
                {{ maxDescriptionLength }} characters.
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex">
              <span class="ml-auto"
                >{{ numCharacters }} of
                {{ maxDescriptionLength }} characters</span
              >
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col class="col-7 col-lg-5">
            <b-form-group id="fg-expiry-date">
              <label class="label-bold" for="expiry-date">
                Expiry date <small class="text-muted"> Optional</small>
                <sf-date-picker
                  id="expiry-date"
                  v-model="expiryDate"
                  :state="!$v.expiryDate.$dirty ? null : true"
                  @input="$v.expiryDate.$touch()"
                ></sf-date-picker>
              </label>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="small mb-0">
              When filling out a form you can select a stored file or upload a
              new file. If a stored file is selected only the file is made
              available to the funder.
            </p>
            <p class="small mb-0">
              Other attributes such as the description and expiry date will not
              be shared.
            </p>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template v-slot:modal-footer>
      <div>
        <b-button variant="dark" @click="hideModal" class="mr-3">
          Cancel
        </b-button>
        <b-overlay
          :show="isSaving"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          id="save-button-overlay"
        >
          <b-button
            :disabled="isSaveButtonDisabled"
            @click="isInEditMode ? editFile() : addFile()"
            variant="primary"
          >
            <span>
              {{ saveButtonText }}
            </span>
          </b-button>
        </b-overlay>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import numeral from "numeral";
import {requiredIf} from "vuelidate/lib/validators";
import handleApiError from "@/shared/apiErrorUtil";
import SfDatePicker from "@/components/interface/SfDatePicker.vue";
import SfFileUpload from "@/components/interface/SfFileUpload.vue";
import {processErrors} from "@/store/fileStore";

export default {
  name: "FileUploadModal",
  components: {SfFileUpload, SfDatePicker},
  props: {
    orgId: {
      String,
    },
    selectedFile: {
      Object,
      default: null,
    },
    maxFileSize: {
      Number,
      default: 25000000,
    },
  },
  data() {
    return {
      modalId: "file-upload-modal",
      title: "Add File",
      uploadLabel: "Upload File",
      saveButtonText: "Add",
      isSaving: false,
      maxDescriptionLength: 250,
      maxFileNameLength: 250,
      file: null,
      name: null,
      description: null,
      mimeType: null,
      size: null,
      expiryDate: null,
      serverSideErrors: {
        file: [],
        virusScannerError: null,
      },
    };
  },
  computed: {
    ...mapState("fileStore", {mimeTypes: state => state.mimeTypes}),
    ...mapState("fileStore", {
      isLoadingMimeTypes: state => state.isLoadingMimeTypes,
    }),
    isInEditMode() {
      return this.selectedFile != null;
    },
    currentFileName() {
      return this.selectedFile ? this.selectedFile.name : "";
    },
    numCharacters() {
      if (this.description) {
        return this.description.length;
      } else {
        return 0;
      }
    },
    formattedFileSize() {
      if (this.file) {
        return this.byteFormatter(this.file.size);
      } else if (this.selectedFile) {
        return this.byteFormatter(this.selectedFile.size);
      } else {
        return "0 KB";
      }
    },
    isSaveButtonDisabled() {
      return !this.$v.$anyDirty || this.$v.$invalid || this.isSaving;
    },
  },
  validations: {
    file: {
      required: requiredIf(vm => !vm.isInEditMode),
      maxFileSize: (file, vm) => {
        return file ? file.size < vm.$props.maxFileSize : true;
      },
      maxFileNameLength: (file, vm) => {
        return file ? file.name.length < vm.maxFileNameLength : true;
      },
      fileType: (file, vm) => {
        if (file) {
          // Added here as an extra check
          // If mimeTypes haven't been loaded to the frontend for some reason.
          // try validating type using the backend, this shouldn't happen
          // unless the fetch mine types request fails
          // in the component mount function
          if (vm.isLoadingMimeTypes) {
            return true;
          }

          return vm.isMimeTypeAllowed()(file.type);
        }
        return true;
      },
      noServerSideErrors: (file, vm) => {
        return vm.serverSideErrors.file
          ? vm.serverSideErrors.file.length === 0
          : true;
      },
    },
    description: {
      maxLength: (description, vm) => {
        return vm.numCharacters <= vm.maxDescriptionLength;
      },
    },
    expiryDate: true, // just for dirty checking and proper styling
  },
  methods: {
    ...mapGetters("fileStore", ["isMimeTypeAllowed"]),
    ...mapActions("fileStore", ["saveFile", "updateFile"]),
    resetModal() {
      this.isSaving = false;
      this.file = null;
      this.name = null;
      this.description = null;
      this.size = null;
      this.expiryDate = null;
      this.mimeType = null;
      this.serverSideErrors = {};
    },
    byteFormatter(bytes) {
      return numeral(bytes).format("0.0 b");
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    showModal() {
      this.$v.$reset(); // Important to reset $dirty fields
      if (this.isInEditMode) {
        this.title = "Edit File";
        this.uploadLabel = "Replace File";
        this.saveButtonText = "Save";
        this.description = this.selectedFile.description;
        this.expiryDate = this.selectedFile.expiryDate;
      } else {
        this.title = "Add File";
        this.uploadLabel = "Upload file";
        this.saveButtonText = "Add";
      }
    },
    fileInputChangeHandler() {
      this.serverSideErrors.file = [];
      this.serverSideErrors.virusScannerError = null;
      this.$v.file.$touch();
    },
    addFile() {
      if (!this.file) {
        this.$bvToast.toast("Select a file to upload", {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      this.isSaving = true;

      const fileData = {
        name: this.file.name,
        size: this.file.size,
        mimeType: this.file.type,
        description: this.description,
        expiryDate: this.expiryDate,
        orgId: this.orgId,
      };

      this.saveFile({
        dto: fileData,
        file: this.file,
      })
        .then(() => this.hideModal())
        .catch(error => this.displayErrors(error))
        .finally(() => (this.isSaving = false));
    },
    editFile() {
      let fileData;

      if (this.file) {
        fileData = {
          name: this.file.name,
          size: this.file.size,
          mimeType: this.file.type,
          description: this.description,
          expiryDate: this.expiryDate,
          orgId: this.orgId,
        };
      } else {
        fileData = {
          id: this.selectedFile.id,
          name: this.selectedFile.name,
          size: this.selectedFile.size,
          mimeType: this.selectedFile.type,
          description: this.description,
          expiryDate: this.expiryDate,
          orgId: this.orgId,
        };
      }
      this.isSaving = true;

      this.updateFile({
        dto: fileData,
        file: this.file,
        fileId: this.selectedFile.id,
      })
        .then(() => this.hideModal())
        .catch(error => this.displayErrors(error))
        .finally(() => (this.isSaving = false));
    },
    displayErrors(error) {
      const errorList = processErrors(error, {
        maxFileSize: this.byteFormatter(this.maxFileSize),
      });

      if (errorList.otherErrors.length > 0) {
        // In these cases there's no need to display all errors,
        // so display the first error.
        handleApiError(error, this, errorList.otherErrors[0]);
      } else if (errorList.fileValidationErrors.length > 0) {
        errorList.fileValidationErrors.forEach(e =>
          this.serverSideErrors.file.push(e)
        );
      } else if (errorList.virusScannerError) {
        this.serverSideErrors.virusScannerError = errorList.virusScannerError;
      } else {
        handleApiError(error, this, error.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../content/scss/vendor.scss";

button:disabled {
  cursor: not-allowed;
}
#expiry-date {
  width: 16em;
}
.custom-invalid-feedback {
  width: 100%;
  margin-top: $form-feedback-margin-top;
  font-size: $form-feedback-font-size;
  color: $form-feedback-invalid-color;
}
</style>

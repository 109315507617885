<template>
  <div class="d-sm-flex mb-2">
    <div class="mr-3 mb-2 contact__tags">
      <div>
        <!-- Div needed to ensure error message displays correctly -->
        <sf-multiselect
          v-model="$v.address.tags.$model"
          :options="tags"
          :class="{'is-danger': !validateStateTags()}"
          :multiple="true"
          :taggable="true"
          :show-labels="false"
          :searchable="false"
          open-direction="bottom"
          :option-height="100"
          @input="onTagSelect"
        >
        </sf-multiselect>
      </div>
      <b-form-invalid-feedback id="tagerr" :state="validateStateTags()">
        {{ errMsgTag }}
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import SfMultiselect from "@/components/interface/SfMultiselect";
import {required} from "vuelidate/lib/validators";

export default {
  name: "AddressTag",
  mixins: [validationMixin],
  components: {
    SfMultiselect,
  },
  model: {
    prop: "address",
    event: "input",
  },
  props: {
    address: Object,
    addressArray: Array,
    tags: Array,
    index: Number,
  },
  data() {
    return {};
  },
  validations: {
    address: {
      tags: {
        required,
        mustBePrimary(val) {
          let isPrimary = false;
          if (val === "") {
            return isPrimary;
          }
          if (val[0] === "Primary") {
            isPrimary = true;
          }
          this.$v.address.tags.$model.forEach(st => {
            if (st === "Primary") isPrimary = true;
          });
          this.addressArray.forEach((ar, i) => {
            if (i !== this.index) {
              this.addressArray[i].tags.forEach(t => {
                if (t === "Primary") {
                  isPrimary = true;
                }
              });
            }
          });
          return isPrimary;
        },
      },
    },
  },
  computed: {
    errMsgTag: function () {
      const {$dirty, $error} = this.$v.address.tags;
      if ($dirty && $error) {
        if (this.$v.address.tags && this.$v.address.tags.required === false) {
          return "Required";
        } else if (
          this.$v.address.tags &&
          this.$v.address.tags.mustBePrimary === false
        ) {
          return "At least one tag must be primary.";
        } else return "";
      } else return "";
    },
  },
  methods: {
    fieldId(str) {
      return str + "_" + this.index;
    },
    remove(obj) {
      this.$emit("remove", obj);
    },
    onTagSelect() {
      this.$v.address.tags.$touch();
      this.$emit("tagSelect", this.address);
    },
    validateStateTags() {
      const {$dirty} = this.$v.address.tags;
      if ($dirty) {
        if (this.$v.address.tags.$model.length < 1) {
          return false;
        }
        return this.$v.address.tags.mustBePrimary;
      } else {
        return true;
      }
    },
    isValid() {
      this.$v.address.$touch();
      return (
        (this.$v.address.tags.$model &&
          this.$v.address.tags.$model.length > 0 &&
          this.$v.address.tags.mustBePrimary) === true
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";

div.contact__tags {
  width: 23rem;
}

.contact__tags {
  ::v-deep .multiselect__tag {
    color: #fff;
    background-color: $primary;
    font-size: 90%;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }

  ::v-deep .multiselect__tag-icon {
    color: rgb(253, 253, 253);
    background-color: $primary;
    font-size: 90%;
    font-weight: 700;
    line-height: 1.3;
    content: "\D7";
    &:focus {
      &::after {
        color: $white;
      }
    }
    &:hover {
      color: $white;
      background-color: $gray-600;
      &::after {
        color: $white;
      }
    }
    &:after {
      color: $white;
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
@import "../../../content/scss/vendor";
.is-danger {
  .multiselect__tags {
    border-color: $red;
  }
}
</style>

/*
 * Function ensure for a given array of *contact* details,
 * selected tag is unique
 */
export function forceUniqueTags(ar, o) {
  let i = ar.indexOf(o);
  // Loop through every selected tag
  o.tags.forEach(tag => {
    // Loop through all other objects in the array
    ar.forEach((element, index) => {
      if (index !== i) {
        //Loop through all tags in the current array element
        let tagsInCurrentArrayElement = element.tags.length;
        while (tagsInCurrentArrayElement--) {
          if (element.tags[tagsInCurrentArrayElement] === tag) {
            element.tags.splice(tagsInCurrentArrayElement, 1);
          }
        }
      }
    });
  });
  return ar;
}

export function clearEmptyContactFields(array, key) {
  const filteredData = array.filter(
    entry => !(entry[key] === "" && entry.tags.length === 0)
  );
  array.splice(0, array.length, ...filteredData);
}

export function clearEmptyAddressFields(array) {
  const filteredData = array.filter(
    (entry, index) =>
      !(
        entry.addressLine1 === "" &&
        entry.addressLine2 === "" &&
        entry.country === "" &&
        entry.postcode === "" &&
        entry.state === "" &&
        entry.suburbLocality === "" &&
        entry.tags === ""
      )
  );
  array.splice(0, array.length, ...filteredData);
}

export function scrollToError(id) {
  const elem = document.getElementById(id);
  window.scrollBy({
    top: elem.getBoundingClientRect().top - 10,
    behavior: "smooth",
  });
  return false;
}

<template>
  <div class="tags_detail_tag_container">
    <ul class="tags_detail_unordered_list">
      <li
        class="tags_detail_tags_list"
        v-for="tagLabel in tagLabels"
        :key="tagLabel.label"
      >
        <b-badge class="tags_detail_tag" variant="primary">
          {{ tagLabel }}
        </b-badge>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "tagsDetail",
  props: {
    tagLabels: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style scoped>
.tags_detail_tag_container .tags_detail_unordered_list {
  margin-bottom: 0;
  padding-left: 2px;
}

.tags_detail_tag_container {
  display: inline-block;
  margin-left: 0;
}
.tags_detail_tags_list {
  list-style-type: none;
  display: inline;
}
.tags_detail_tag {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.2rem;
}
</style>

<template>
  <div>
    <div v-for="(address, index) in addresses" :key="index">
      <address-lookup
        ref="addresslookup"
        :address="address"
        :addressArray="addresses"
        :countryCode="countryCode"
        :index="index"
        :tags="tags"
        @tagSelect="onTagSelect"
        @remove="remove"
        @update="update"
      />
    </div>
    <div class="d-flex mb-4" v-if="showAddRow">
      <button
        class="btn btn-link pl-0 add_another focus:outline-none"
        v-on:click="addRow"
        type="button"
        aria-disabled="true"
      >
        Add another...
      </button>
    </div>
  </div>
</template>

<script>
import AddressLookup from "./AddressLookup";

export default {
  name: "AddressInputList",
  components: {
    AddressLookup,
  },
  model: {
    prop: "addresses",
    event: "input",
  },
  props: {
    addresses: Array,
    countryCode: String,
    tags: Array,
  },
  computed: {
    showAddRow() {
      return this.addresses.length < this.tags.length;
    },
  },
  data() {
    return {};
  },
  methods: {
    addRow() {
      this.$emit("addRow");
    },
    onTagSelect(selectedTag) {
      this.$emit("tagSelect", "addresses", this.addresses, selectedTag);
    },
    update(obj) {
      this.$emit("update", "addresses", obj);
    },
    remove(obj) {
      this.$emit("remove", "addresses", obj);
    },
    isValid() {
      if (this.$refs.addresslookup) {
        const error = Object.values(
          this.$refs.addresslookup.filter(ref => !ref.isValid())
        );
        return error.length <= 0;
      } else {
        return true;
      }
    },
  },
};
</script>

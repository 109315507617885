<template>
  <!-- Read view of the Contacts -->
  <div class="bg">
    <div class="row row-cols-md-2 row-cols-1">
      <div v-if="showAddress" id="headOfOrg_address" class="col">
        <info-line heading="h6" label="Address">
          <tagged-list-multiple
            :values="contactObj.addresses_merged"
            key-name="address"
          />
        </info-line>
      </div>
      <div v-if="showEmails" class="col">
        <info-line heading="h6" label="Email" id="contact_emails">
          <tagged-list-multiple :values="contactObj.emails" key-name="email" />
        </info-line>
      </div>
      <div v-if="showPhone" class="col">
        <info-line heading="h6" label="Phone">
          <tagged-list-multiple
            :values="contactObj.phoneNumbers"
            key-name="number"
          />
        </info-line>
      </div>
      <div v-if="showWebsite" class="col">
        <info-line heading="h6" label="Website">
          <tagged-list-multiple :values="contactObj.websites" key-name="url" />
        </info-line>
      </div>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";
import TaggedListMultiple from "../interface/TaggedListMultiple";

export default {
  name: "ContactDetailView",
  components: {
    InfoLine,
    TaggedListMultiple,
  },
  props: ["contactObj"],
  computed: {
    showEmails() {
      return (
        this.contactObj &&
        this.contactObj.emails &&
        this.contactObj.emails.length > 0
      );
    },
    showPhone() {
      return (
        this.contactObj &&
        this.contactObj.phoneNumbers &&
        this.contactObj.phoneNumbers.length > 0
      );
    },
    showAddress() {
      return (
        this.contactObj &&
        this.contactObj.addresses_merged &&
        this.contactObj.addresses_merged.length > 0
      );
    },
    showWebsite() {
      return (
        this.contactObj &&
        this.contactObj.websites &&
        this.contactObj.websites.length > 0
      );
    },
  },
};
</script>

<template>
  <div>
    <div v-if="!showAddressForm">
      <div class="d-sm-flex mb-2">
        <div class="flex-grow-1 mr-3 mb-2 address__suggestions">
          <div>
            <sf-multiselect
              v-model="selectedAddress"
              label="displayValue"
              :class="{'is-danger': missingSelectedAddress}"
              track-by="displayValue"
              placeholder="Type to search"
              open-direction="bottom"
              :options="suggestions"
              :multiple="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :options-limit="300"
              :limit="maxSearchResults"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :preserve-search="true"
              @select="addressSelected"
              @search-change="asyncFind"
            >
            </sf-multiselect>
          </div>
        </div>
        <address-tag
          ref="addresstag"
          :address="address"
          :addressArray="addressArray"
          :index="index"
          :tags="tags"
          @tagSelect="onTagSelect"
        />
        <div class="mb-2 mt-2 text-center fix-height">
          <clickable-icon
            icon="x-circle"
            :id="fieldId('removeButton')"
            variant="danger"
            sr-label="remove"
            @click="remove(address)"
          />
        </div>
      </div>
    </div>

    <address-input
      ref="addressinput"
      :address="address"
      :addressArray="addressArray"
      :index="index"
      :tags="tags"
      :show="showAddressForm"
      @hideAddressForm="hideAddressForm"
      @tagSelect="onTagSelect"
      @remove="remove"
    />
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import handleApiError from "../../shared/apiErrorUtil";
import AddressInput from "./AddressInput";
import AddressTag from "./AddressTag";
import ClickableIcon from "@/components/interface/ClickableIcon";
import SfMultiselect from "@/components/interface/SfMultiselect";
import {mapActions} from "vuex";
import _ from "lodash";

const ManualAddressOption = {
  displayValue: "Can't find your address?",
  address: null,
  placeholder: true,
};
const AUS_MAP_VIEWPORT = {
  lat: -28.77,
  lng: 130.87,
  radius: 2000000,
  zoom: 3,
};
const NZ_MAP_VIEWPORT = {
  lat: -42.16,
  lng: 171.61,
  radius: 400000,
  zoom: 5,
};

export default {
  name: "AddressLookup",
  mixins: [validationMixin],
  components: {
    AddressInput,
    ClickableIcon,
    AddressTag,
    SfMultiselect,
  },
  model: {
    prop: "address",
    event: "input",
  },
  props: {
    address: Object,
    addressArray: Array,
    countryCode: String,
    tags: Array,
    index: Number,
  },
  watch: {
    address: function () {
      this.selectedAddress = {
        displayValue: this.getDisplayAddress(this.address),
      };
    },
  },
  computed: {
    missingSelectedAddress() {
      return (
        this.showErrors &&
        (this.selectedAddress.displayValue === undefined ||
          this.selectedAddress.displayValue === "")
      );
    },
  },
  data() {
    return {
      copyAddress: {},
      selectedAddress: {
        displayValue: this.getDisplayAddress(this.address),
      },
      suggestions: [ManualAddressOption],
      showAddressForm: false,
      isLoading: false,
      maxSearchResults: 5,
      showErrors: false,
    };
  },
  methods: {
    ...mapActions("hereMapsStore", [
      "fetchAddressSuggestions",
      "fetchAddressGeoLocation",
    ]),
    remove(obj) {
      this.$emit("remove", obj);
    },
    onTagSelect(val) {
      this.$emit("tagSelect", val);
    },
    update() {
      this.$emit("update", {original: this.address, updated: this.copyAddress});
    },
    getDefaultSuggestion() {
      return ManualAddressOption;
    },
    hideAddressForm() {
      this.showAddressForm = false;
      if (this.$refs.addressinput.isValid(true)) {
        this.selectedAddress = {
          displayValue: this.getDisplayAddress(this.address),
        };
        if (this.selectedAddress["displayValue"] !== "") {
          this.findAddressGeoLocation(this.selectedAddress["displayValue"]);
        }
      } else {
        this.selectedAddress = {displayValue: ""};
      }
      this.suggestions = [this.getDefaultSuggestion()];
    },
    addressSelected(selection) {
      if (
        selection["displayValue"] ===
        this.getDefaultSuggestion()["displayValue"]
      ) {
        this.showAddressForm = true;
      } else {
        this.copyAddress.addressLine1 = selection.address.addressLine1;
        this.copyAddress.addressLine2 = selection.address.addressLine2;
        this.copyAddress.suburbLocality = selection.address.suburbLocality;
        this.copyAddress.state = selection.address.state;
        this.copyAddress.country = selection.address.country;
        this.copyAddress.postcode = selection.address.postcode;
        this.update();
        this.findAddressGeoLocation(selection["displayValue"]);
      }
    },
    determineInstanceMapViewPortDetails: function () {
      switch (this.countryCode) {
        case "NZ":
          return NZ_MAP_VIEWPORT;
      }
      return AUS_MAP_VIEWPORT;
    },
    asyncFind: _.debounce(function (query) {
      this.isLoading = true;
      this.findAddressSuggestions(query);
    }, 500),
    getDisplayAddress: function (address) {
      if (address) {
        let addressParts = [];
        if (address.addressLine1) addressParts.push(address.addressLine1);
        if (address.addressLine2) addressParts.push(address.addressLine2);
        if (address.suburbLocality) addressParts.push(address.suburbLocality);
        if (address.state) addressParts.push(address.state);
        if (address.postcode) addressParts.push(address.postcode);
        if (address.country) addressParts.push(address.country);
        return addressParts.join(", ");
      } else {
        return "";
      }
    },
    findAddressSuggestions(query) {
      const viewPort = this.determineInstanceMapViewPortDetails();
      return this.fetchAddressSuggestions({
        query: query,
        maxSearchResults: this.maxSearchResults,
        viewPort: viewPort,
      })
        .then(response => {
          this.setSuggestions(response);
        })
        .catch(error => handleApiError(error, this));
    },
    findAddressGeoLocation(query) {
      return this.fetchAddressGeoLocation(query)
        .then(response => {
          this.setAddressGeoLocation(response.geoTime, response.geoView);
        })
        .catch(error => handleApiError(error, this));
    },
    setAddressGeoLocation(geoDate, geoLocation) {
      this.copyAddress.latitude =
        geoLocation === null
          ? null
          : geoLocation.Location.DisplayPosition.Latitude;
      this.copyAddress.longitude =
        geoLocation === null
          ? null
          : geoLocation.Location.DisplayPosition.Longitude;
      this.copyAddress.locationId =
        geoLocation === null ? null : geoLocation.Location.LocationId;
      this.copyAddress.geoProvider = "HERE";
      this.copyAddress.geoDate = geoDate;
      this.update();
    },
    setSuggestions(result) {
      this.isLoading = false;
      if (result.suggestions && result.suggestions.length > 0) {
        this.suggestions = [];

        for (let i = 0; i < result.suggestions.length; i++) {
          let suggestedAddr = result.suggestions[i].address;
          let houseNumber =
            suggestedAddr.houseNumber !== undefined
              ? suggestedAddr.houseNumber + " "
              : "";
          let address = {
            addressLine1:
              houseNumber +
              (suggestedAddr.street !== undefined ? suggestedAddr.street : ""),
            country:
              suggestedAddr.country !== undefined ? suggestedAddr.country : "",
            postcode:
              suggestedAddr.postalCode !== undefined
                ? suggestedAddr.postalCode
                : "",
            state: suggestedAddr.state !== undefined ? suggestedAddr.state : "",
            suburbLocality:
              suggestedAddr.district !== undefined
                ? suggestedAddr.district
                : "",
          };
          this.suggestions.push({
            displayValue: this.getDisplayAddress(address),
            address: address,
          });
        }
        this.suggestions.push(this.getDefaultSuggestion());
      }
    },
    isValid() {
      if (this.showAddressForm) {
        return this.$refs.addressinput.isValid();
      } else {
        this.showErrors = true;
        let missingSelectedAddress =
          this.selectedAddress.displayValue === undefined ||
          this.selectedAddress.displayValue === "";
        let missingTag = !this.$refs.addresstag.isValid();
        return !(missingSelectedAddress || missingTag);
      }
    },
    fieldId(str) {
      return str + "_" + this.index;
    },
    getAusMapViewPort() {
      return AUS_MAP_VIEWPORT;
    },
    getNZMapViewPort() {
      return NZ_MAP_VIEWPORT;
    },
  },
};
</script>

<style lang="scss">
@import "../../../content/scss/vendor";
.is-danger {
  .multiselect {
    border-color: $red;
  }
}

.address__suggestions .multiselect .multiselect__content-wrapper {
  box-shadow: 5px 5px 10px grey;
}
.address__suggestions .multiselect .multiselect__content-wrapper .placeholder {
  color: $of_blue;
}
</style>

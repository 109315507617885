<template>
  <div>
    <div v-for="(contactType, index) in contactTypeArray" :key="index">
      <contact-input
        ref="contactinput"
        :contactType="contactType"
        :keyName="keyName"
        :keyNameDisplay="keyNameDisplay"
        :placeHolderTxt="placeHolderTxt"
        :arrayName="arrayName"
        :contactArray="contactTypeArray"
        :tags="tags"
        :index="index"
        :validator="validator"
        @remove="remove"
        @tagSelect="onTagSelect"
      />
    </div>
    <div class="d-flex mb-4" v-if="showAddRow">
      <button
        class="btn btn-link pl-0 add_another focus:outline-none"
        v-on:click="addRow"
        type="button"
        aria-disabled="true"
      >
        Add another...
      </button>
    </div>
  </div>
</template>

<script>
import ContactInput from "./ContactInput";

export default {
  name: "ContactInputList",
  components: {
    ContactInput,
  },
  model: {
    prop: "contactTypeArray",
    event: "input",
  },
  props: {
    contactTypeArray: Array,
    tags: Array,
    arrayName: String,
    keyName: String,
    keyNameDisplay: String,
    placeHolderTxt: String,
    validator: Function,
  },
  computed: {
    showAddRow() {
      return this.contactTypeArray.length < this.tags.length;
    },
  },
  methods: {
    addRow() {
      this.$emit("addRow", this.arrayName, this.keyName);
    },
    onTagSelect(selectedTag) {
      this.$emit(
        "tagSelect",
        this.arrayName,
        this.contactTypeArray,
        selectedTag
      );
    },
    remove(obj) {
      this.$emit("remove", this.arrayName, obj);
    },
    isValid() {
      if (this.$refs.contactinput) {
        const error = Object.values(
          this.$refs.contactinput.filter(ref => !ref.isValid())
        );
        return error.length <= 0;
      } else {
        return true;
      }
    },
  },
};
</script>

import {email} from "vuelidate/lib/validators";

export function emailValidator(value) {
  return email(value);
}

export function numberValidator(value) {
  const phoneNumberRegex = /^\+?[ 0-9]+$/;
  return phoneNumberRegex.test(value);
}

export function urlValidator(value) {
  const urlRegex = /(\w+\.\w+)+/;
  return urlRegex.test(value);
}

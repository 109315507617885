<template>
  <div class="sf-date-picker">
    <b-input-group>
      <b-form-input
        v-model="dateString"
        :placeholder="placeholder"
        type="date"
        autocomplete="off"
        required
        class="sf-date-picker-input"
        :class="isValid"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          class="sf-date-picker-btn"
          size="sm"
          button-variant="primary"
          v-model="dateString"
          :date-format-options="{
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }"
          button-only
          tabindex="0"
          dropdown
          right
          locale="en-AU"
          :state="state"
          :label-no-date-selected="placeholder"
        ></b-form-datepicker>
      </b-input-group-append>
      <button
        @click="clearButtonClickHandler"
        class="btn btn-outline-primary btn-sm ml-3"
      >
        Clear
      </button>
    </b-input-group>
  </div>
</template>

<script>
import moment, {ISO_8601} from "moment";

export default {
  name: "SfDatePicker",
  props: {
    value: String, // Internal model value will be maintained in ISO_8601 format
    placeholder: {
      type: String,
      default: "dd/mm/yyyy",
    },
    state: {
      type: [Boolean, null],
      required: false,
    },
  },
  computed: {
    dateString: {
      get() {
        return this.parseIsoDate(this.value);
      },
      set(val) {
        this.$emit("input", this.toIsoDate(val));
      },
    },
    isValid() {
      if (this.state === null) {
        return "";
      } else if (this.state) {
        return "is-valid";
      } else {
        return "is-invalid";
      }
    },
  },
  methods: {
    toIsoDate(date, defaultFormat = "YYYY-MM-DD") {
      return moment(date, defaultFormat).toISOString(true);
    },
    parseIsoDate(date, defaultFormat = "YYYY-MM-DD") {
      const formattedDate = moment(date, ISO_8601).format(defaultFormat);
      if (formattedDate === "Invalid date") {
        return undefined;
      } else {
        return formattedDate;
      }
    },
    clearButtonClickHandler() {
      this.dateString = null;
    },
  },
};
</script>

<style scoped>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.sf-date-picker-input {
  font-size: 0.8rem;
  cursor: text;
  color: gray;
}
::v-deep .sf-date-picker-btn > button {
  border-radius: 0 0.2em 0.2em 0 !important;
}
</style>

<template>
  <button
    class="btn btn-link p-0 scaling"
    type="button"
    @click="$emit('click')"
    :style="scaleVar"
    v-if="enabled"
  >
    <b-icon :icon="icon" :variant="variant" aria-hidden="true" />
    <span class="sr-only">{{ srLabel }}</span>
  </button>
  <button
    v-else
    class="btn btn-link p-0"
    type="button"
    :style="scaleVar"
    disabled
  >
    <b-icon :icon="icon" variant="secondary" aria-hidden="true" />
    <span class="sr-only">{{ srLabel }}</span>
  </button>
</template>

<script>
export default {
  name: "ClickableIcon",
  props: {
    icon: {type: String},
    variant: {type: String, default: "primary"},
    srLabel: {type: String, default: "Button"},
    scale: {type: Number, default: 1.75},
    enabled: {type: Boolean, default: true},
  },
  computed: {
    scaleVar() {
      return {"--scale": this.scale};
    },
  },
};
</script>

<style scoped>
button.scaling > * {
  transition: all 0.2s ease-out;
}
button.scaling:hover > * {
  transform: scale(var(--scale));
}
button:focus {
  box-shadow: none;
}
</style>

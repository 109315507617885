<template>
  <div>
    <div class="d-sm-flex mb-2">
      <div class="flex-grow-1 mr-3 mb-2 contact_row">
        <div>
          <b-form-input
            :id="fieldId('input')"
            class="contact_field"
            v-model="$v.contactType[keyName].$model"
            :placeholder="placeHolderTxt"
            :state="validateState()"
          />
          <b-form-invalid-feedback :state="validateState()">
            {{ errMsgKey }}
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="mr-3 mb-2 contact__tags">
        <div>
          <!-- Div needed to ensure error message displays correctly -->
          <sf-multiselect
            v-model="$v.contactType.tags.$model"
            :id="fieldId('tagMultiSelect')"
            ref="tagMultiSelect"
            :class="{'is-danger': !validateStateTags()}"
            :options="tags"
            :multiple="true"
            :taggable="true"
            :show-labels="false"
            :searchable="false"
            open-direction="bottom"
            :option-height="100"
            @input="onTagSelect"
          >
          </sf-multiselect>
        </div>
        <b-form-invalid-feedback id="tagerr" :state="validateStateTags()">
          {{ errMsgTag }}
        </b-form-invalid-feedback>
      </div>
      <div class="mb-2 text-center fix-height">
        <clickable-icon
          :id="fieldId('removeButton')"
          icon="x-circle"
          variant="danger"
          sr-label="remove"
          @click="remove($v.contactType.$model)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import ClickableIcon from "@/components/interface/ClickableIcon";
import {required} from "vuelidate/lib/validators";
import SfMultiselect from "@/components/interface/SfMultiselect";
import {mapGetters} from "vuex";

export default {
  name: "ContactInput",
  mixins: [validationMixin],
  components: {
    ClickableIcon,
    SfMultiselect,
  },
  model: {
    prop: "contactType",
    event: "input",
  },
  props: {
    contactType: Object,
    contactArray: Array,
    index: Number,
    keyName: String,
    keyNameDisplay: String,
    placeHolderTxt: String,
    tags: Array,
    validator: Function,
  },
  computed: {
    errMsgKey: function () {
      const {$dirty, $error} = this.$v.contactType[this.keyName];
      if ($dirty && $error) {
        if (
          this.$v.contactType[this.keyName] &&
          this.$v.contactType[this.keyName].required === false
        ) {
          return "Required";
        } else if (
          this.$v.contactType[this.keyName] &&
          this.$v.contactType[this.keyName].unique === false
        ) {
          return "This " + this.keyNameDisplay + " has already been used.";
        } else return "Please enter a valid " + this.keyNameDisplay + ".";
      } else return "";
    },
    errMsgTag: function () {
      const {$error} = this.$v.contactType.tags;
      if ($error) {
        if (
          this.$v.contactType.tags &&
          this.$v.contactType.tags.tagRequired === false
        ) {
          return "Required";
        } else if (
          this.$v.contactType.tags &&
          this.$v.contactType.tags.mustBePrimary === false
        ) {
          return "At least one tag must be primary.";
        } else {
          return "";
        }
      } else if (
        this.$v.contactType.tags.$model &&
        this.$v.contactType.tags.$model.length < 1
      ) {
        return "Required";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      selectedTag: [],
      forceUpdate: true,
    };
  },
  validations() {
    if (this.contactType) {
      return {
        contactType: {
          [this.keyName]: {
            required,
            unique(val) {
              if (val === "") return true;
              let isUnique = true;
              this.contactArray.forEach((ar, i) => {
                if (
                  i !== this.index &&
                  ar[this.keyName] === this.$v.contactType[this.keyName].$model
                ) {
                  isUnique = false;
                }
              });
              return isUnique;
            },

            foo: this.validator,
          },
          tags: {
            tagRequired(val) {
              if (val && val.length <= 0) return false;
              else return true;
            },
            mustBePrimary(val) {
              let isPrimary = false;
              if (!val || val === "") {
                return isPrimary;
              }
              if (val[0] === "Primary") {
                return true;
              }
              isPrimary = this.selectedTag.includes("Primary");
              this.contactArray.forEach((ar, i) => {
                if (i !== this.index) {
                  if (this.contactArray[i].tags.includes("Primary")) {
                    isPrimary = true;
                  }
                }
              });
              return isPrimary;
            },
          },
        },
      };
    } else {
      return {};
    }
  },
  methods: {
    ...mapGetters("auth", ["getCurrentUser"]),
    onTagSelect(val) {
      this.selectedTag = val;
      this.$emit("tagSelect", this.contactType);
      this.$v.$touch();
    },
    remove(obj) {
      this.$emit("remove", obj);
    },
    validateState() {
      const {$dirty, $error} = this.$v.contactType[this.keyName];
      return $dirty ? !$error : null;
    },
    validateStateTags() {
      if (!this.$v.contactType.tags) {
        return false;
      }
      if (
        !this.$v.contactType.tags.$model ||
        this.$v.contactType.tags.$model.length < 1
      ) {
        return false;
      }
      return !!this.$v.contactType.tags.mustBePrimary;
    },
    isValid() {
      this.$v.contactType.$touch();
      return !this.$v.contactType.$invalid;
    },
    fieldId(str) {
      return str + "_" + this.keyName + "_" + this.index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";

.fix-height {
  padding-top: 0.375rem;
}

.contact__tags {
  width: 23rem;
}

.contact__tags {
  ::v-deep .multiselect__tag {
    color: #fff;
    background-color: $primary;
    font-size: 90%;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0 !important;
    margin-right: 5px !important;
  }

  ::v-deep .multiselect__tag-icon {
    &:focus {
      &::after {
        color: $white;
      }
    }
    &:hover {
      color: $white;
      background-color: $gray-600;
      &::after {
        color: $white;
      }
    }
    &:after {
      color: $white;
    }
  }
}

.is-danger {
  .multiselect__tags {
    border-color: $red;
  }
}
</style>

<template>
  <b-card :body-class="cardClass" :border-variant="borderColor" title-tag="h1">
    <b-card-title>
      {{ title }}
      <clickable-icon
        v-if="isAdmin && isRead"
        @click="toggle"
        icon="pencil"
        sr-label="Edit"
      />
    </b-card-title>
    <component :is="currentComponent" v-bind="childProps" ref="comp" />
    <template v-slot:footer v-if="isAdmin && !isRead">
      <b-button variant="primary" @click="toggle" class="mr-3">
        {{ buttonText }}
      </b-button>
      <b-button variant="dark" @click="setReadView"> Cancel </b-button>
    </template>
  </b-card>
</template>

<script>
/**
 * A card component that shows a read view and an edit view for the given data.
 * This component manages the transition from view to edit and vice-versa.
 * The edit component MUST provide a save() method that return a Promise that
 * MAY contain a success message to be displayed.
 * Properties passed in childProps will be passed directly to both the read and
 * the edit components.
 */
import ClickableIcon from "../interface/ClickableIcon";
export default {
  name: "EditableCard",
  props: {
    title: String,
    isAdmin: {
      type: Boolean,
      required: true,
    },
    readView: Object,
    editView: Object,
    childProps: Object,
    showEdit: Boolean,
    borderColor: {
      type: String,
      required: false,
      default: "rgba(0, 0, 0, 0.125)",
    },
    showPadding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    ClickableIcon,
  },
  computed: {
    buttonText() {
      return this.isRead ? "Edit" : "Save";
    },
    cardClass() {
      return this.showPadding
        ? "editable_card_padding"
        : "editable_card_no_padding";
    },
    currentComponent() {
      return this.isRead ? this.readView : this.editView;
    },
  },
  data() {
    return {
      isRead: !this.showEdit,
    };
  },
  methods: {
    toggle() {
      if (this.isAdmin) {
        if (this.isRead) {
          this.isRead = false;
        } else {
          this.$refs.comp
            .save()
            .then(message => {
              this.$bvToast.toast(message, {
                title: "Success",
                autoHideDelay: 5000,
                variant: "success",
                solid: true,
              });
              this.setReadView();
            })
            .catch(err => {
              this.isRead = false;
              console.log("There was an error saving: " + JSON.stringify(err));
            });
        }
      }
    },
    setReadView() {
      this.isRead = true;
    },
  },
};
</script>
<style scoped>
.editable_card_padding {
  padding: 1rem;
}

.editable_card_no_padding {
  padding: 2px;
}
</style>

<template>
  <div v-if="show" class="address">
    <a class="address_search_link" @click="showSearchInput">
      Return to address search
    </a>
    <div class="d-sm-flex mb-4 address_row">
      <label class="address_label">Address Line 1</label>
      <div class="flex-grow-1">
        <b-form-input
          :maxlength="20"
          class="address_input"
          :id="fieldId('addressline1')"
          v-model="$v.address.addressLine1.$model"
          :state="validateState('addressLine1')"
          placeholder="Address Line 1"
        />
        <b-form-invalid-feedback
          :state="validateState('addressLine1')"
          class="err_addressline"
        >
          {{ errMsgKey("addressLine1") }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="d-sm-flex mb-3 address_row">
      <label class="address_label">Address Line 2</label>
      <div class="flex-grow-1">
        <b-form-input
          :maxlength="20"
          class="address_input"
          :id="fieldId('addressline2')"
          v-model="$v.address.addressLine2.$model"
          :state="validateState('addressLine2')"
          placeholder="Address Line 2"
        />
        <b-form-invalid-feedback
          :state="validateState('addressLine2')"
          class="err_addressline"
        >
          {{ errMsgKey("addressLine2") }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="d-sm-flex">
      <div class="flex-grow-1 mb-3 address_row">
        <label class="address_label">Suburb</label>
        <div>
          <b-form-input
            :maxlength="20"
            class="address_input suburb"
            :id="fieldId('suburb')"
            v-model="$v.address.suburbLocality.$model"
            :state="validateState('suburbLocality')"
            placeholder="Suburb"
          />
          <b-form-invalid-feedback
            class="suburb"
            :state="validateState('suburbLocality')"
          >
            {{ errMsgKey("suburbLocality") }}
          </b-form-invalid-feedback>
        </div>
        <label class="address_label">Post Code</label>
        <div>
          <b-form-input
            :maxlength="20"
            class="address_input postcode"
            :id="fieldId('postcode')"
            v-model="$v.address.postcode.$model"
            :state="validateState('postcode')"
            placeholder="Post Code"
          />
          <b-form-invalid-feedback :state="validateState('postcode')">
            {{ errMsgPostCode }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="d-sm-flex">
      <div class="flex-grow-1 mb-3 address_row">
        <label class="address_label">State</label>
        <div>
          <b-form-input
            :maxlength="20"
            class="address_input state"
            :id="fieldId('state')"
            v-model="$v.address.state.$model"
            :state="validateState('state')"
            placeholder="State"
          />
          <b-form-invalid-feedback
            class="state"
            :state="validateState('state')"
          >
            {{ errMsgKey("state") }}
          </b-form-invalid-feedback>
        </div>
        <label class="address_label country">Country</label>
        <div>
          <b-form-input
            :maxlength="20"
            class="address_input country"
            :id="fieldId('country')"
            v-model="$v.address.country.$model"
            :state="validateState('country')"
            placeholder="Country"
          />
          <b-form-invalid-feedback :state="validateState('country')">
            {{ errMsgKey("country") }}
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
    <div class="d-sm-flex mb-1 address_row">
      <div class="address_label">Tag</div>
      <address-tag
        ref="addresstag"
        :address="address"
        :addressArray="addressArray"
        :index="index"
        :tags="tags"
        @tagSelect="onTagSelect"
      />
      <div class="mb-1 text-center fix-height">
        <clickable-icon
          icon="x-circle"
          :id="fieldId('removeButton')"
          variant="danger"
          sr-label="remove"
          @click="remove($v.address.$model)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {minLength, numeric, required} from "vuelidate/lib/validators";
import ClickableIcon from "@/components/interface/ClickableIcon";
import AddressTag from "./AddressTag";

export default {
  name: "AddressInput",
  mixins: [validationMixin],
  components: {
    ClickableIcon,
    AddressTag,
  },
  model: {
    prop: "address",
    event: "input",
  },
  props: {
    address: Object,
    index: Number,
    addressArray: Array,
    tags: Array,
    show: Boolean,
  },
  computed: {
    errMsgPostCode: function () {
      const {$dirty, $error} = this.$v.address["postcode"];
      if ($dirty && $error) {
        if (
          this.$v.address["postcode"] &&
          this.$v.address["postcode"].required === false
        ) {
          return "Required";
        } else if (
          this.$v.address["postcode"] &&
          this.$v.address["postcode"].numeric === false
        ) {
          return "Must be a number";
        } else if (
          this.$v.address["postcode"] &&
          this.$v.address["postcode"].minLength === false
        ) {
          return "Enter 4 digits";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      bsbPlaceholder: true,
      showAddRow: true,
      bsbHasError: false,
    };
  },
  validations: {
    address: {
      addressLine1: {
        required,
      },
      addressLine2: {},
      suburbLocality: {
        required,
      },
      postcode: {
        numeric,
        minLength: minLength(4),
        required,
      },
      state: {
        required,
      },
      country: {},
    },
  },
  methods: {
    errMsgKey: function (val) {
      const {$dirty, $error} = this.$v.address[val];
      if ($dirty && $error) {
        if (this.$v.address[val] && this.$v.address[val].required === false) {
          return "Required";
        }
        if (this.$v.address[val] && this.$v.address[val].required === false) {
          return "Required";
        } else if (
          this.$v.address[val] &&
          this.$v.address[val].unique === false
        ) {
          return "This value has already been entered.";
        }
        {
          return "Please enter a valid " + this.keyNameDisplay + ".";
        }
      } else {
        return "";
      }
    },
    remove(obj) {
      this.$emit("remove", obj);
    },
    onTagSelect(val) {
      this.$emit("tagSelect", this.address);
    },
    showSearchInput() {
      this.$emit("hideAddressForm");
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.address[name];
      return $dirty ? !$error : null;
    },
    isValid(ignoreTags) {
      this.$v.address.$touch();
      if (ignoreTags === true) {
        return !this.$v.address.$invalid;
      } else {
        let missingTag = !this.$refs.addresstag.isValid();
        return !this.$v.address.$invalid && !missingTag;
      }
    },
    fieldId(str) {
      return str + "_" + this.index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";

.address {
  margin-top: 1rem;
}

.address_row {
  line-height: 1.3;
  align-items: center;
  display: flex;
  vertical-align: middle;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
}

.address_label {
  margin-right: 1rem;
  min-width: 8rem;
  text-align: end;
  padding-top: 0.4rem;
}

.address_input {
  max-width: 22rem;
}

.fix-height {
  padding-top: 0.375rem;
}

.suburb {
  width: 22rem;
}

.state {
  width: 22rem;
}
</style>

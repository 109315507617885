<template>
  <div  class="pb-1">
    <b-form-group label="Address" label-for="address">
      <address-input-list
        id="address"
        ref="address"
        v-model="currentContact.addresses"
        :tags="contactTags.tagsAddress"
        :countryCode="getCountryCode()"
        @addRow="addRowAddress"
        @remove="removeEntry"
        @tagSelect="onTagSelect"
        @update="updateEntry"
      />
    </b-form-group>
    <b-form-group label="Email" label-for="emails">
      <contact-input-list
        id="emails"
        ref="emails"
        keyName="email"
        arrayName="emails"
        keyNameDisplay="email address"
        placeHolderTxt="e.g. ollie@example.com"
        v-model="currentContact.emails"
        :tags="contactTags.tagsEmail"
        :validator="validators.emailValidator"
        @addRow="addRow"
        @remove="removeEntry"
        @tagSelect="onTagSelect"
      />
    </b-form-group>
    <b-form-group label="Phone" label-for="phone">
      <contact-input-list
        id="phone"
        ref="phone"
        keyName="number"
        arrayName="phoneNumbers"
        keyNameDisplay="phone number"
        placeHolderTxt="e.g. 0556677888"
        v-model="currentContact.phoneNumbers"
        :tags="contactTags.tagsPhone"
        :validator="validators.numberValidator"
        @addRow="addRow"
        @remove="removeEntry"
        @tagSelect="onTagSelect"
      />
    </b-form-group>
    <b-form-group label="Website" label-for="web">
      <contact-input-list
        id="web"
        ref="web"
        keyName="url"
        arrayName="websites"
        keyNameDisplay="web address"
        placeHolderTxt="e.g. www.example.com"
        v-model="currentContact.websites"
        :tags="contactTags.tagsWeb"
        :validator="validators.urlValidator"
        @addRow="addRow"
        @remove="removeEntry"
        @tagSelect="onTagSelect"
      />
    </b-form-group>
  </div>
</template>

<script>
import {contactTags} from "@/utils/staticdata";
import ContactInputList from "@/components/interface/ContactInputList";
import AddressInputList from "@/components/interface/AddressInputList";
import {
  emailValidator,
  numberValidator,
  urlValidator,
} from "@/utils/validators";

export default {
  name: "IndividualContactEdit",
  components: {
    AddressInputList,
    ContactInputList,
  },
  props: ["contact"],
  data() {
    return {
      contactTags: contactTags,
      validators: {
        emailValidator: emailValidator,
        numberValidator: numberValidator,
        urlValidator: urlValidator,
      },
      currentContact: this.contact,
    };
  },
  methods: {
    addRow(arrayName, name) {
      this.$emit("addRow", arrayName, name);
    },
    addRowAddress() {
      this.$emit("addRowAddress");
    },
    removeEntry(name, obj) {
      this.$emit("remove", name, obj);
    },
    onTagSelect(name, contactArray, selectedEntry) {
      this.$emit("tagSelect", name, contactArray, selectedEntry);
    },
    invalidFields() {
      const errors = [];

      if (this.$refs.emails && !this.$refs.emails.isValid()) {
        errors.push("emails");
      }
      if (this.$refs.phone && !this.$refs.phone.isValid()) {
        errors.push("phone");
      }
      if (this.$refs.web && !this.$refs.web.isValid()) {
        errors.push("web");
      }
      if (this.$refs.address && !this.$refs.address.isValid()) {
        errors.push("address");
      }
      return errors;
    },
    updateEntry(name, obj) {
      const index = this.currentContact[name].indexOf(obj.original); // should never be -1
      Object.assign(this.currentContact[name][index], obj.updated);
    },
    getCountryCode() {
      if (this.org && this.org.nzbn && this.org.nzbn.length > 0) {
        return "NZ";
      }
      return "AU";
    },
  },
};
</script>
